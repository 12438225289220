import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import localStorageBackend from "i18next-localstorage-backend";
import languageDetector from "i18next-browser-languagedetector";
import jaJson from "@src/locales/ja.json";
import enJson from "@src/locales/en.json";
import zhJson from "@src/locales/zh.json";
import { locales } from "../constants/locales";

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [localStorageBackend],
    },
    resources: {
      ja: { translation: jaJson },
      // en: { translation: enJson },
      // zh: { translation: zhJson },
    },
    fallbackLng: locales.ja,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
